import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.css";

import MainGameComponent from "./game/MainGameComponent";
import Home from "./pages/Home";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route exact path="/game" element={<MainGameComponent />} />
            </Routes>
        </Router>
    );
}

export default App;

