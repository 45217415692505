import { useEffect, useState } from "react";
import { handleAuth } from "../utils/auth";
import { Link } from "react-router-dom";

const Home = () => {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("ut");
        const exp = localStorage.getItem("exp");

        if (token && exp) {
            const expiryDate = new Date(parseInt(exp) * 1000);
            if (expiryDate > new Date()) {
                setLoggedIn(true);
            } else {
                handleAuth();
            }
        } else {
            handleAuth();
        }
    }, []);

    return (
        <div className="App">
            <h1>HOME PLACEHOLDER</h1>
            <h2>FIRST LOG IN</h2>
            <button onClick={handleAuth}>log in</button>
            <h2>THEN PLAY</h2>
            <button>
                <Link to="/game">play</Link>
            </button>
        </div>
    );
};

export default Home;

