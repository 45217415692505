import Player from "./Player";

export default class OtherPlayer extends Player {
    constructor(scene, x, y, texture, name, id) {
        super(scene, x, y, texture, name, id);
        this.targetPosition = { x, y };
        this.lastUpdateTimestamp = 0;
        this.firstMove = true;
    }

    move(x, y, anim) {
        this.targetPosition = { x, y };
        this.lastDirection = anim;
    }

    preUpdate(t, dt) {
        super.preUpdate(t, dt);

        if (
            this.firstMove &&
            this.x === 300 &&
            this.y === 300 &&
            (this.targetPosition.x !== 300 || this.targetPosition.y !== 300)
        ) {
            this.x = this.targetPosition.x;
            this.y = this.targetPosition.y;
            this.playerContainer.x = this.x;
            this.playerContainer.y = this.y;
            this.firstMove = false;
            return;
        }

        if (this.lastUpdateTimestamp && t - this.lastUpdateTimestamp > 750) {
            this.lastUpdateTimestamp = t;
            this.x = this.targetPosition.x;
            this.y = this.targetPosition.y;
            this.playerContainer.x = this.x;
            this.playerContainer.y = this.y;
            return;
        }
        this.lastUpdateTimestamp = t;
        this.handleMovement(this.targetPosition.x, this.targetPosition.y, dt);
    }

    handleMovement(targetX, targetY, dt) {
        const body = this.body;

        const delta = (this.moveSpeed / 1500) * dt;
        let dx = targetX - this.x;
        let dy = targetY - this.y;

        if (Math.abs(dx) < delta) {
            this.x = targetX;
            dx = 0;
        }
        if (Math.abs(dy) < delta) {
            this.y = targetY;
            dy = 0;
        }

        // Reset velocity
        let velocityX = 0;
        let velocityY = 0;

        if (dx > 0) velocityX += this.moveSpeed;
        else if (dx < 0) velocityX -= this.moveSpeed;
        if (dy > 0) velocityY += this.moveSpeed;
        else if (dy < 0) velocityY -= this.moveSpeed;

        if (velocityX > 0) {
            this.play("walk-right", true);
        } else if (velocityX < 0) {
            this.play("walk-left", true);
        } else if (velocityY > 0) {
            this.play("walk-down", true);
        } else if (velocityY < 0) {
            this.play("walk-up", true);
        } else {
            this.play(`idle-${this.lastDirection}`, true);
        }

        body.setVelocity(velocityX, velocityY);
        body.velocity.setLength(this.moveSpeed);

        this.playerContainer.x = body.x; //- this.containerOffset[0];
        this.playerContainer.y = body.y; //- this.containerOffset[1];
    }
}

