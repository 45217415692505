import Phaser from "phaser";

export default class Player extends Phaser.GameObjects.Sprite {
    constructor(scene, x, y, texture, name, id) {
        super(scene, x, y, texture);
        scene.add.existing(this);
        scene.physics.add.existing(this);

        this.lastDirection = "down";
        this.moveSpeed = 150;
        this.setScale(1.5);
        this.chatBox = null;
        this.id = id;

        this.bodyOffset = [10, 21];
        this.bodySize = [13, 12];
        this.containerOffset = [-30, 42];

        this.body.setSize(this.bodySize[0], this.bodySize[1]);
        this.body.setOffset(this.bodyOffset[0], this.bodyOffset[1]);

        var fontSize = 10;

        this.playerContainer = this.scene.add
            .container(this.x, this.y)
            .setDepth(this.y);
        this.playerName = this.scene.add
            .text(10, -35, name, {
                stroke: "#000000",
                strokeThickness: 2,
                fontFamily: "arial",
                fontSize: fontSize,
                fontStyle: "bold",
                color: "#ffffff",
            })
            .setOrigin(0.5, 0.5)
            .setResolution(10);
        this.playerContainer.add(this.playerName);

        this.scene.physics.world.enable(this.playerContainer);
    }

    renderChat(message) {
        if (this.chatBox) {
            this.chatBox.destroy();
            clearTimeout(this.chatBoxTimeout);
        }

        // this needs tweaking
        const chatBoxPadding = 2;
        const chatBoxPaddingHeight = 2;
        const fontSize = 11;
        const maxMessageWidth = 20 * (fontSize + chatBoxPadding); // maximum width of the chat box
        const messageLength = message.length;
        const chatBoxWidth = Math.min(
            messageLength * (fontSize * 0.6) + 2 * chatBoxPadding,
            maxMessageWidth,
        );
        const chatBoxHeight =
            Math.ceil((messageLength * (fontSize * 0.6)) / maxMessageWidth) *
                (fontSize * 1.5) +
            2 * chatBoxPaddingHeight +
            2;

        this.chatBox = this.scene.add.container(10, -35).setDepth(5000);

        const chatBackground = this.scene.add
            .rectangle(0, -20, chatBoxWidth, chatBoxHeight, 0xffffff)
            .setOrigin(0.5)
            .setStrokeStyle(2, 0x000000);

        const chatText = this.scene.add
            .text(0, -20, message, {
                fontFamily: "monospace",
                fontSize: fontSize,
                color: "#000000",
                wordWrap: { width: chatBoxWidth },
            })
            .setOrigin(0.5)
            .setResolution(10);

        this.chatBox.add(chatBackground);
        this.chatBox.add(chatText);

        this.playerContainer.add(this.chatBox);

        // this timer seems to stop on tabbing out of the game
        this.chatBoxTimeout = setTimeout(() => {
            if (this.chatBox) {
                this.chatBox.destroy();
                this.chatBox = null;
            }
        }, 5000);
    }

    speak(isSpeaking) {
        if (isSpeaking) {
            this.playerName.setColor("#00ff00");
        } else {
            this.playerName.setColor("#ffffff");
        }
    }

    setPlayerDepth(depth) {
        this.setDepth(depth);
        this.playerContainer.setDepth(depth);
    }

    setPlayerVisible(visible) {
        this.setVisible(visible);
        this.playerContainer.setVisible(visible);
    }
}

