import React, { useState, useRef, useEffect } from "react";
import { EventBus, MyEvents } from "../game/EventBus";
import chatIcon from "../images/Message-Three-Dots.png";
const Message = ({ from, message }) => {
    return (
        <div className="message-container">
            <p className="message-from">{from}:</p>
            <p className="message-message">{message}</p>
        </div>
    );
};

const ChatWindow = ({ scene, isOpen, setIsOpen }) => {
    const inputRef = useRef(null);
    const chatContentRef = useRef(null);
    const networkRef = useRef(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const handleMessage = (msg) => {
            setMessages((prevMessages) => [
                ...prevMessages,
                { from: msg.name, message: msg.content },
            ]);
        };

        EventBus.on(MyEvents.MESSAGE, handleMessage);

        return () => {
            EventBus.off(MyEvents.MESSAGE, handleMessage);
        };
    }, []);

    useEffect(() => {
        if (scene && scene.scene.key === "Game") {
            networkRef.current = scene.network;
        }
    }, [scene]);

    function focusChat() {
        scene.disableKeys();
        inputRef.current?.focus();
    }

    function blurChat() {
        inputRef.current?.blur();
        if (scene) {
            scene.enableKeys();
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (
                document.activeElement === inputRef.current &&
                event.key === "Enter"
            ) {
                event.preventDefault();
                sendMessage();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                blurChat();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    async function sendMessage() {
        let message = inputRef.current.value;
        inputRef.current.value = "";
        if (message.length === 0) {
            console.log("Message is empty");
            return;
        }

        let result = await networkRef.current.sendMessage(message);
        if (result.success) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { from: "You", message: message },
            ]);
            EventBus.emit(MyEvents.MY_MESSAGE, {
                name: "You",
                content: message,
            });
        } else {
            console.error("Message failed to send:", result);
        }
    }

    function renderMessages() {
        return messages.map((msg, index) => (
            <Message key={index} from={msg.from} message={msg.message} />
        ));
    }

    if (scene && scene.scene.key === "Game") {
        return (
            <>
                <div
                    className="chat-window"
                    style={{ display: isOpen ? "inherit" : "none" }}
                    id="ui-layer"
                >
                    <button
                        className="inner-chat-toggle game-button"
                        onClick={() => {
                            if (chatContentRef.current) {
                                chatContentRef.current.scrollTop =
                                    chatContentRef.current.scrollHeight;
                            }
                            setIsOpen(false);
                        }}
                    >
                        X
                    </button>
                    <div className="chat-content" ref={chatContentRef}>
                        {renderMessages()}
                    </div>
                    <textarea
                        className="chat-textarea"
                        onFocus={focusChat}
                        onBlur={blurChat}
                        ref={inputRef}
                        maxLength={100}
                    ></textarea>
                </div>
                <button
                    className="chat-toggle game-button"
                    style={{ display: isOpen ? "none" : "block" }}
                    onClick={() => setIsOpen(true)}
                    id="ui-layer"
                    title="chat"
                >
                    <img
                        className="game-icon"
                        src={chatIcon}
                        width={25}
                        height={25}
                    />
                </button>
            </>
        );
    }
};

export default ChatWindow;

