import { MyEvents, EventBus } from "../EventBus";
import { Scene } from "phaser";
import { createCharacterAnims } from "../../anim/CharacterAnim";
import MyPlayer from "../../characters/MyPlayer";
import OtherPlayer from "../../characters/OtherPlayer";
import Player from "../../characters/Player";

export class Game extends Scene {
    constructor() {
        super("Game");
        this.player = null;
        this.otherPlayers = new Map();
        this.entities = []; // all collision objects
        this.tilemapObjects = []; // all objects

        // annoying JS thing to be able to use the methods outside
        this.enableKeys = this.enableKeys.bind(this);
        this.disableKeys = this.disableKeys.bind(this);
        this.createFurnitureColliders =
            this.createFurnitureColliders.bind(this);
        this.createFurnitureObject = this.createFurnitureObject.bind(this);
        this.createDecorationObject = this.createDecorationObject.bind(this);
        this.loadBuildModeMapState = this.loadBuildModeMapState.bind(this);
        this.renderMapData = this.renderMapData.bind(this);
        this.saveBuildModeMapState = this.saveBuildModeMapState.bind(this);

        // build mode stuff
        this.mapLayers = {
            floor: null,
            walls: null,
            furniture: null,
            decorations: null,
        };
        this.initMapData = null;
        this.buildModeMapData = null;
        this.buildMode = false;
        this.buildLayer = null;
        this.buildTileIndex = null;
        this.toggleBuildMode = this.toggleBuildMode.bind(this);
        this.defaultZoom = 2;
        this.buildZoom = 0.75;
        this.selectedTile = null;
        this.tileHighlight = null;
        this.tileSize = 32;
        this.mapBounds = {
            width: 38, // Initial map width in tiles
            height: 38, // Initial map height in tiles
        };
        this.isDragging = false; // Flag to track dragging
        this.previousTile = { x: null, y: null }; // Store previous tile coordinates for click and drag
        this.joystickMovement = {
            isMoving: false,
            direction: {
                up: false,
                down: false,
                left: false,
                right: false,
            },
        };
    }

    handleJoystickMovement(movement) {
        this.joystickMovement = movement;
        this.player.updateJoystickMovement(movement);
    }

    disableKeys() {
        this.input.keyboard.enabled = false;
        this.input.keyboard.resetKeys();
    }

    enableKeys() {
        this.input.keyboard.enabled = true;
    }

    isTilePlacementValid(tileX, tileY) {
        if (
            !this.isWithinMapBounds(tileX, tileY) &&
            !this.isInUnlockedArea(tileX, tileY)
        ) {
            return false;
        }

        // add more validation rules here

        return true;
    }

    isWithinMapBounds(tileX, tileY) {
        return (
            tileX >= 0 &&
            tileX < this.mapBounds.width &&
            tileY >= 0 &&
            tileY < this.mapBounds.height
        );
    }

    isInUnlockedArea(tileX, tileY) {
        // impliment later down the line players can unlock new rooms.
        return false;
    }

    copyTileData(data) {
        return data.map((row) => [...row]);
    }

    updateTileHighlight(tileX, tileY, layer) {
        // Clear previous highlight
        this.tileHighlight.clear();

        const isValid = this.isTilePlacementValid(tileX, tileY);

        // Set color based on validity
        const borderColor = isValid ? 0x00ff00 : 0xff0000; // Green if valid, red if invalid
        const fillColor = isValid ? 0x00ff00 : 0xff0000;
        const fillAlpha = 0.2;

        // Draw new highlight
        this.tileHighlight.lineStyle(2, borderColor, 1);
        this.tileHighlight.strokeRect(
            tileX * this.tileSize,
            tileY * this.tileSize,
            this.tileSize,
            this.tileSize,
        );

        // Add semi-transparent fill
        this.tileHighlight.fillStyle(fillColor, fillAlpha);
        this.tileHighlight.fillRect(
            tileX * this.tileSize,
            tileY * this.tileSize,
            this.tileSize,
            this.tileSize,
        );
    }

    selectTile(tileX, tileY, layer) {
        // Only select the tile if placement is valid
        if (!this.isTilePlacementValid(tileX, tileY)) {
            return;
        }

        // Example: Get tile from the map layer (use later for validation)
        const tileAtPosition = this.map.getTileAt(tileX, tileY, true, layer);
        if (tileAtPosition) {
            console.log("Tile index:", tileAtPosition.index);
        }

        let tileset;
        let gid;

        if (this.buildTileIndex === null) {
            this.buildTileIndex = 1;
        }

        if (layer === null) {
            layer = "floor";
            tileset = this.map.tilesets.find(
                (tileset) => tileset.name === layer,
            );
        } else if (layer === "chairs") {
            layer = "furniture";
            tileset = this.map.tilesets.find(
                (tileset) => tileset.name === "chairs",
            );
        } else {
            tileset = this.map.tilesets.find(
                (tileset) => tileset.name === layer,
            );
        }

        gid = tileset ? tileset.firstgid : 0;

        this.mapLayers[layer].putTileAt(
            this.buildTileIndex + gid - 1,
            tileX,
            tileY,
        );
        this.buildModeMapData[layer][tileY * this.mapBounds.width + tileX] =
            this.buildTileIndex + gid - 1;
    }

    loadBuildModeMapState() {
        const savedState = localStorage.getItem("buildModeMapState");
        let updateMapData = false;
        this.buildModeMapData = JSON.parse(JSON.stringify(this.initMapData));
        if (savedState) {
            this.buildModeMapData = JSON.parse(savedState);
            updateMapData = true;
        }

        return updateMapData;
    }

    saveBuildModeMapState() {
        localStorage.setItem(
            "buildModeMapState",
            JSON.stringify(this.buildModeMapData),
        );
    }

    renderMapData(mapData) {
        for (let row = 0; row < this.mapBounds.height; row++) {
            for (let col = 0; col < this.mapBounds.width; col++) {
                const floorTile = this.mapLayers.floor.getTileAt(col, row);
                const tileIndex =
                    mapData.floor[row * this.mapBounds.width + col];

                if (floorTile && floorTile.index !== tileIndex) {
                    let index = tileIndex;
                    if (tileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.floor.putTileAt(index, col, row);
                } else if (!floorTile) {
                    let index = tileIndex;
                    if (tileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.floor.putTileAt(index, col, row);
                }

                const wallsTile = this.mapLayers.walls.getTileAt(col, row);
                const wallsTileIndex =
                    mapData.walls[row * this.mapBounds.width + col];

                if (wallsTile && wallsTile.index !== wallsTileIndex) {
                    let index = wallsTileIndex;
                    if (wallsTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.walls.putTileAt(index, col, row);
                } else if (!wallsTile) {
                    let index = wallsTileIndex;
                    if (wallsTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.walls.putTileAt(index, col, row);
                }

                const furnitureTile = this.mapLayers.furniture.getTileAt(
                    col,
                    row,
                );
                const furnitureTileIndex =
                    mapData.furniture[row * this.mapBounds.width + col];

                if (
                    furnitureTile &&
                    furnitureTile.index !== furnitureTileIndex
                ) {
                    let index = furnitureTileIndex;
                    if (furnitureTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.furniture.putTileAt(index, col, row);
                } else if (!furnitureTile) {
                    let index = furnitureTileIndex;
                    if (furnitureTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.furniture.putTileAt(index, col, row);
                }

                const decorationsTile = this.mapLayers.decorations.getTileAt(
                    col,
                    row,
                );
                const decorationsTileIndex =
                    mapData.decorations[row * this.mapBounds.width + col];

                if (
                    decorationsTile &&
                    decorationsTile.index !== decorationsTileIndex
                ) {
                    let index = decorationsTileIndex;
                    if (decorationsTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.decorations.putTileAt(index, col, row);
                } else if (!decorationsTile) {
                    let index = decorationsTileIndex;
                    if (decorationsTileIndex == 0) {
                        index = -1;
                    }
                    this.mapLayers.decorations.putTileAt(index, col, row);
                }
            }
        }
    }

    toggleBuildMode() {
        this.buildMode = !this.buildMode;

        if (this.buildMode) {
            this.player.isInBuildMode = true;
            this.player.setPlayerVisible(false);
            this.tileHighlight.setVisible(true);

            this.otherPlayers.forEach((player) =>
                player.setPlayerVisible(false),
            );

            const updateMapData = this.loadBuildModeMapState();
            if (updateMapData) {
                this.renderMapData(this.buildModeMapData);
            }

            this.cameras.main.stopFollow();
            this.cameras.main.setZoom(this.buildZoom);
            const mapWidth = this.cameras.main.worldView.width;
            const mapHeight = this.cameras.main.worldView.height;
            this.cameras.main.centerOn(
                mapWidth / 2 + (this.mapBounds.width / 4) * this.tileSize,
                mapHeight / 2 + (this.mapBounds.height / 4) * this.tileSize,
            ); // Center camera on the map

            this.tilemapObjects.forEach((object) => {
                object.setVisible(false);
            });
            this.mapLayers.floorCopy.setVisible(false);
            this.mapLayers.wallsCopy.setVisible(false);

            this.mapLayers.floor.setVisible(true);
            this.mapLayers.walls.setVisible(true);
            this.mapLayers.furniture.setVisible(true);
            this.mapLayers.decorations.setVisible(true);
        } else {
            this.player.isInBuildMode = false;
            this.player.setPlayerVisible(true);
            this.tileHighlight.setVisible(false);

            this.otherPlayers.forEach((player) =>
                player.setPlayerVisible(true),
            );

            this.cameras.main.startFollow(this.player);
            this.cameras.main.setZoom(this.defaultZoom);

            this.tilemapObjects.forEach((object) => {
                object.setVisible(true);
            });

            this.mapLayers.floorCopy.setVisible(true);
            this.mapLayers.wallsCopy.setVisible(true);

            this.mapLayers.floor.setVisible(false);
            this.mapLayers.walls.setVisible(false);
            this.mapLayers.furniture.setVisible(false);
            this.mapLayers.decorations.setVisible(false);

            this.saveBuildModeMapState();
        }
    }

    createFurnitureColliders(tile) {
        if (tile.index === -1) {
            return;
        }

        let tileData;

        if (
            this.furniture &&
            tile.index >= this.furniture.firstgid &&
            tile.index < this.furniture.firstgid + this.furniture.total
        ) {
            tileData =
                this.furniture.tileData[tile.index - this.furniture.firstgid];
        } else if (
            this.chairs &&
            tile.index >= this.chairs.firstgid &&
            tile.index < this.chairs.firstgid + this.chairs.total
        ) {
            tileData = this.chairs.tileData[tile.index - this.chairs.firstgid];
        } else if (
            this.decorations &&
            tile.index >= this.decorations.firstgid &&
            tile.index < this.decorations.firstgid + this.decorations.total
        ) {
            tileData =
                this.decorations.tileData[
                    tile.index - this.decorations.firstgid
                ];
        }

        if (!tileData) {
            return;
        }

        let firstObject = tileData.objectgroup.objects[0];

        let colBoxX = tile.pixelX + firstObject.x;
        let colBoxY = tile.pixelY + firstObject.y;
        let colBoxW = firstObject.width;
        let colBoxH = firstObject.height;

        let zone = this.add
            .zone(colBoxX, colBoxY, colBoxW, colBoxH)
            .setOrigin(0);
        this.physics.world.enable(zone, 0); // (0) DYNAMIC (1) STATIC
        zone.body.setAllowGravity(false);
        zone.body.moves = false;

        this.physics.world.enable(zone);
        this.furnitureColliders.add(zone);

        if (tile.properties.interact === true) {
            this.interactableColliders.add(zone);
        }
    }

    // Method to create a furniture object at the tile's position
    createFurnitureObject(tile) {
        const x = tile.pixelX;
        const y = tile.pixelY;
        let furnitureObject;
        if (
            this.furniture &&
            tile.index >= this.furniture.firstgid &&
            tile.index < this.furniture.firstgid + this.furniture.total
        ) {
            furnitureObject = this.add.sprite(
                x,
                y,
                "furnitureSprites",
                tile.index - this.furniture.firstgid,
            );
        } else if (
            this.chairs &&
            tile.index >= this.chairs.firstgid &&
            tile.index < this.chairs.firstgid + this.chairs.total
        ) {
            furnitureObject = this.add.sprite(
                x,
                y,
                "chairsSprites",
                tile.index - this.chairs.firstgid,
            );
        }
        furnitureObject.setOrigin(0);
        this.physics.world.enable(furnitureObject);
        furnitureObject.body.setAllowGravity(false);
        furnitureObject.body.moves = false;
        if (tile.properties.alwaysBottom) {
            furnitureObject.setDepth(0);
        } else {
            this.entities.push(furnitureObject);
        }
        this.tilemapObjects.push(furnitureObject);
    }

    // Method to create a decoration object at the tile's position
    createDecorationObject(tile) {
        const x = tile.pixelX;
        const y = tile.pixelY;
        const decorationObject = this.add.sprite(
            x,
            y,
            "decorationsSprites",
            tile.index - this.decorations.firstgid,
        );
        decorationObject.setOrigin(0);
        this.physics.world.enable(decorationObject);
        decorationObject.body.setAllowGravity(false);
        decorationObject.body.moves = false;

        if (tile.properties.alwaysBottom) {
            decorationObject.setDepth(0);
        } else {
            this.entities.push(decorationObject);
        }
        this.tilemapObjects.push(decorationObject);
    }

    create(data) {
        if (!data.network) {
            throw new Error("server instance missing");
        } else {
            this.network = data.network;
        }

        this.initMapData = JSON.parse(JSON.stringify(data.mapData));

        createCharacterAnims(this);
        this.cameras.main.setZoom(this.defaultZoom);
        // Create the tilemap
        this.map = this.make.tilemap({
            key: "map",
            tileWidth: 32,
            tileHeight: 32,
        });

        this.buildModeMap = this.make.tilemap({
            key: "map",
            tileWidth: 32,
            tileHeight: 32,
        });

        this.floor = this.map.addTilesetImage("floor");
        this.walls = this.map.addTilesetImage("walls");
        this.furniture = this.map.addTilesetImage("furniture");
        this.chairs = this.map.addTilesetImage("chairs");
        this.decorations = this.map.addTilesetImage("decorations");

        this.mapLayers.floor = this.map.createLayer("floor", this.floor);
        this.mapLayers.walls = this.map.createLayer("walls", this.walls);

        this.mapLayers.floorCopy = this.map.createLayer(
            "floorCopy",
            this.floor,
        );
        this.mapLayers.wallsCopy = this.map.createLayer(
            "wallsCopy",
            this.walls,
        );

        this.mapLayers.furniture = this.map.createLayer("furniture", [
            this.furniture,
            this.chairs,
        ]);
        this.mapLayers.decorations = this.map.createLayer(
            "decorations",
            this.decorations,
        );

        this.mapLayers.floor.setVisible(false);
        this.mapLayers.walls.setVisible(false);
        this.mapLayers.furniture.setVisible(false);
        this.mapLayers.decorations.setVisible(false);

        // Create objects for each occupied tile in the furniture layer
        this.map.forEachTile(
            (tile) => {
                if (tile.index !== -1) {
                    // Check if the tile is occupied
                    this.createFurnitureObject(tile);
                }
            },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.mapLayers.furniture,
        );

        // Create objects for each occupied tile in the decorations layer
        this.map.forEachTile(
            (tile) => {
                if (tile.index !== -1) {
                    // Check if the tile is occupied
                    this.createDecorationObject(tile);
                }
            },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.mapLayers.decorations,
        );

        // Add your character sprite here
        this.player = new MyPlayer(
            this,
            300,
            300,
            "character",
            data.network.myDisplayName,
            data.network.myInGameID,
        );
        this.cameras.main.startFollow(this.player);
        this.entities.push(this.player);

        // Enable collision for walls
        this.map.setCollisionFromCollisionGroup(
            true,
            true,
            this.mapLayers.walls,
        );

        // Add collider between player and layers
        this.physics.add.collider(this.player, this.mapLayers.walls);

        this.furnitureColliders = this.physics.add.staticGroup();
        this.interactableColliders = this.physics.add.staticGroup();

        this.map.forEachTile(
            this.createFurnitureColliders,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.mapLayers.furniture,
        );

        this.map.forEachTile(
            this.createFurnitureColliders,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this.mapLayers.decorations,
        );

        this.furnitureColliders.children.each((collider) => {
            this.physics.add.collider(this.player, collider);
        });

        this.physics.add.overlap(
            this.player.playerView,
            this.interactableColliders.getChildren(),
            (me, other) => {
                this.player.playerSelectedItem = {
                    x: other.x,
                    y: other.y,
                };

                this.player.playerSelectedItemTime = this.time.now;
            },
            undefined,
            this,
        );

        if (data.otherPlayers) {
            data.otherPlayers.forEach((member) => {
                let name = member.name;
                if (name.length > 15) {
                    name =
                        name.substring(0, 6) +
                        "..." +
                        name.substring(name.length - 6);
                }
                const newPlayer = new OtherPlayer(
                    this,
                    300,
                    300,
                    "character",
                    name,
                    member.inGameID,
                );
                this.otherPlayers.set(member.inGameID, newPlayer);

                this.physics.add.collider(newPlayer, this.mapLayers.walls);

                this.furnitureColliders.children.each((collider) => {
                    this.physics.add.collider(newPlayer, collider);
                });

                // Add collider to prevent players from moving through each other
                this.physics.add.collider(
                    this.player,
                    newPlayer,
                    null,
                    null,
                    this,
                );
                this.entities.push(newPlayer);
            });
        }

        // Create a graphics object for tile highlighting
        this.tileHighlight = this.add.graphics();
        this.tileHighlight.setDepth(999); // Make sure it renders above other elements
        this.tileHighlight.setVisible(false);

        // Add mouse move listener for hover effect
        this.input.on("pointermove", (pointer) => {
            if (this.buildMode) {
                const worldPoint = this.cameras.main.getWorldPoint(
                    pointer.x,
                    pointer.y,
                );
                const tileX = Math.floor(worldPoint.x / this.tileSize);
                const tileY = Math.floor(worldPoint.y / this.tileSize);

                // Update highlight with validation
                this.updateTileHighlight(tileX, tileY, this.buildLayer);

                if (
                    this.isDragging &&
                    this.isTilePlacementValid(tileX, tileY, this.buildLayer)
                ) {
                    // Only select the tile if it's different from the previous one
                    if (
                        tileX !== this.previousTile.x ||
                        tileY !== this.previousTile.y
                    ) {
                        this.selectTile(tileX, tileY, this.buildLayer);
                        this.previousTile = { x: tileX, y: tileY }; // Update previous tile
                    }
                }
            }
        });

        // Add click listener for tile selection
        this.input.on("pointerdown", (pointer) => {
            if (this.buildMode) {
                this.isDragging = true;
                const worldPoint = this.cameras.main.getWorldPoint(
                    pointer.x,
                    pointer.y,
                );
                const tileX = Math.floor(worldPoint.x / this.tileSize);
                const tileY = Math.floor(worldPoint.y / this.tileSize);

                if (this.isTilePlacementValid(tileX, tileY, this.buildLayer)) {
                    this.selectTile(tileX, tileY, this.buildLayer);
                    this.previousTile = { x: tileX, y: tileY };
                }
            }
        });

        this.input.on("pointerup", () => {
            this.isDragging = false; // Stop dragging
            this.previousTile = { x: null, y: null }; // Reset previous tile
        });

        EventBus.on(MyEvents.PLAYER_JOINED, (data) => {
            let name = data.name;
            if (name.length > 15) {
                name =
                    name.substring(0, 6) +
                    "..." +
                    name.substring(name.length - 6);
            }

            const newPlayer = new OtherPlayer(
                this,
                300,
                300,
                "character",
                name,
                data.inGameID,
            );
            this.otherPlayers.set(data.inGameID, newPlayer);
            this.physics.add.collider(newPlayer, this.mapLayers.walls);

            this.furnitureColliders.children.each((collider) => {
                this.physics.add.collider(newPlayer, collider);
            });

            // Add collider to prevent players from moving through each other
            this.physics.add.collider(this.player, newPlayer, null, null, this);
            this.entities.push(newPlayer);
            if (this.buildMode) {
                newPlayer.setPlayerVisible(false);
            }
        });

        EventBus.on(MyEvents.MESSAGE, (data) => {
            const { name, content, inGameID } = data;
            let otherPlayer = this.otherPlayers.get(inGameID);
            if (otherPlayer) {
                otherPlayer.renderChat(content);
            }
        });

        EventBus.on(MyEvents.PLAYER_SPEAKING, (data) => {
            const { peerId, isSpeaking } = data;
            const player = this.otherPlayers.get(peerId);
            if (player) {
                player.speak(isSpeaking);
            }
        });

        EventBus.on(MyEvents.MY_MESSAGE, (data) => {
            const { name, content } = data;
            this.player.renderChat(content);
        });

        EventBus.on(MyEvents.PLAYER_MOVED, (data) => {
            const { inGameID, x, y, anim } = data;
            const player = this.otherPlayers.get(inGameID);
            if (player) {
                player.move(x, y, anim);
            }
        });

        EventBus.on(MyEvents.PLAYER_LEFT, (data) => {
            const { inGameID } = data;
            let player = this.otherPlayers.get(inGameID);
            if (player) {
                player.playerContainer.destroy();
                player.destroy();
                this.otherPlayers.delete(inGameID);
                this.entities = this.entities.filter(
                    (entity) => entity !== player,
                );
            }
        });

        EventBus.on(MyEvents.BUILD_TILE_SELECTED, (data) => {
            const { layer, index } = data;
            this.buildLayer = layer;
            this.buildTileIndex = index;
        });

        EventBus.on(MyEvents.SAVE_BUILD_MODE_MAP_STATE, () => {
            this.saveBuildModeMapState();
        });

        EventBus.on(MyEvents.RESET_BUILD_MODE_MAP_STATE, () => {
            this.buildModeMapData = JSON.parse(
                JSON.stringify(this.initMapData),
            );
            this.renderMapData(this.buildModeMapData);
        });

        EventBus.on(MyEvents.KICKED, () => {
            this.scene.start("Kicked");
        });

        EventBus.emit("current-scene-ready", this);
    }

    handleCameraMovement() {
        const speed = 5; // Adjust the speed as needed

        let joystickLeft = false;
        let joystickRight = false;
        let joystickUp = false;
        let joystickDown = false;

        if (this.joystickMovement?.isMoving) {
            joystickLeft = this.joystickMovement.direction.left;
            joystickRight = this.joystickMovement.direction.right;
            joystickUp = this.joystickMovement.direction.up;
            joystickDown = this.joystickMovement.direction.down;
        }

        let cursors = this.player.cursors;
        // Handle WASD input
        if (cursors.left.isDown || joystickLeft) {
            this.cameras.main.scrollX -= speed;
        } else if (cursors.right.isDown || joystickRight) {
            this.cameras.main.scrollX += speed;
        }

        if (cursors.up.isDown || joystickUp) {
            this.cameras.main.scrollY -= speed;
        } else if (cursors.down.isDown || joystickDown) {
            this.cameras.main.scrollY += speed;
        }

        // Ensure the camera doesn't go out of bounds
        const maxScrollX =
            this.mapBounds.width * this.tileSize - this.cameras.main.width;
        const maxScrollY =
            this.mapBounds.height * this.tileSize - this.cameras.main.height;

        this.cameras.main.scrollX = Phaser.Math.Clamp(
            this.cameras.main.scrollX,
            0,
            maxScrollX,
        );
        this.cameras.main.scrollY = Phaser.Math.Clamp(
            this.cameras.main.scrollY,
            0,
            maxScrollY,
        );
    }

    update() {
        this.player.update();

        if (this.buildMode) {
            this.handleCameraMovement();
        }

        this.entities.sort((a, b) => {
            const aBottom = a.y + (a.body ? a.body.height : 0);
            const bBottom = b.y + (b.body ? b.body.height : 0);

            // orkish code, +5 tolerance for non player entities when deciding draw order
            if (a instanceof Player && b instanceof Player) {
                return aBottom - bBottom;
            } else if (a instanceof Player) {
                return aBottom - bBottom + 5;
            } else if (b instanceof Player) {
                return aBottom + 5 - bBottom;
            } else {
                return aBottom - bBottom;
            }
        });

        this.entities.forEach((entity, index) => {
            entity.setDepth(index);
        });
    }

    changeScene() {}
}

