import { useRef, useState, useEffect } from "react";
import { PhaserGame } from "./PhaserGame";
import ChatWindow from "../components/ChatWindow";
import PlayerWindow from "../components/PlayerWindow";
import BuildWindow from "../components/BuildWindow";
import JoystickComponent from "../components/Joystick";
import { PlayerProvider } from "../context/PlayerContext";
import "../app.css";

function MainGameComponent() {
    const phaserRef = useRef();
    const [currentScene, setCurrentScene] = useState(null);
    const [chatOpen, setChatOpen] = useState(false);
    const [playersOpen, setPlayersOpen] = useState(false);
    const [buildOpen, setBuildOpen] = useState(false);
    const [joystickOpen, setJoystickOpen] = useState(false);
    function handleSetChatOpen(isTrue) {
        if (isTrue) {
            setPlayersOpen(false);
            setBuildOpen(false);
        }
        setChatOpen(isTrue);
    }

    function handleSetPlayersOpen(isTrue) {
        if (isTrue) {
            setChatOpen(false);
            setBuildOpen(false);
        }
        setPlayersOpen(isTrue);
    }

    function handleSetBuildOpen(isTrue) {
        if (isTrue) {
            setChatOpen(false);
            setPlayersOpen(false);
        }
        setBuildOpen(isTrue);
    }

    return (
        <PlayerProvider>
            <div id="app">
                <ChatWindow
                    scene={currentScene}
                    isOpen={chatOpen}
                    setIsOpen={handleSetChatOpen}
                />
                <PlayerWindow
                    scene={currentScene}
                    isOpen={playersOpen}
                    setIsOpen={handleSetPlayersOpen}
                />
                <BuildWindow
                    scene={currentScene}
                    isOpen={buildOpen}
                    setIsOpen={handleSetBuildOpen}
                />
                <JoystickComponent
                    scene={currentScene}
                    isOpen={joystickOpen}
                    setIsOpen={setJoystickOpen}
                />
                <PhaserGame
                    ref={phaserRef}
                    currentActiveScene={setCurrentScene}
                />
            </div>
        </PlayerProvider>
    );
}

export default MainGameComponent;

