import { useState, useRef, useEffect } from "react";
import { EventBus, MyEvents } from "../game/EventBus";
import homeIcon from "../images/Home.png";
import TileSheetViewer from "./TileSheetViewer";
import floorTileSheet from "../images/tilesheets/floor.png";
import wallsTileSheet from "../images/tilesheets/walls.png";
import furnitureTileSheet from "../images/tilesheets/furniture.png";
import chairTileSheet from "../images/tilesheets/chairs.png";
import decorationsTileSheet from "../images/tilesheets/decorations.png";

const BuildWindow = ({ scene, isOpen, setIsOpen }) => {
    const [buildMode, setBuildMode] = useState(false);
    const [selectedLayer, setSelectedLayer] = useState("floor");
    const [selectedIndex, setSelectedIndex] = useState(0);

    function toggleBuildMode() {
        setBuildMode(!buildMode);
        scene.toggleBuildMode();
    }

    function handleTileSelection(index) {
        setSelectedIndex(index);
    }

    function handleLayerSelection(layer) {
        setSelectedLayer(layer);
    }

    function handleSaveBuildModeMapState() {
        EventBus.emit(MyEvents.SAVE_BUILD_MODE_MAP_STATE);
    }

    function handleResetBuildModeMapState() {
        EventBus.emit(MyEvents.RESET_BUILD_MODE_MAP_STATE);
    }

    function handleSubmitBuildModeMapState() {
        //submit for voting
    }

    useEffect(() => {
        EventBus.emit(MyEvents.BUILD_TILE_SELECTED, {
            layer: selectedLayer,
            index: selectedIndex,
        });
    }, [selectedLayer, selectedIndex]);

    function handleTileSheetViewer() {
        switch (selectedLayer) {
            case "floor":
                return (
                    <TileSheetViewer
                        tileSheetSrc={floorTileSheet}
                        setSelectedIndex={handleTileSelection}
                        gridColumns={7}
                    />
                );
            case "walls":
                return (
                    <TileSheetViewer
                        tileSheetSrc={wallsTileSheet}
                        setSelectedIndex={handleTileSelection}
                        gridColumns={7}
                    />
                );
            case "furniture":
                return (
                    <TileSheetViewer
                        tileSheetSrc={furnitureTileSheet}
                        setSelectedIndex={handleTileSelection}
                        gridColumns={8}
                    />
                );
            case "chairs":
                return (
                    <TileSheetViewer
                        tileSheetSrc={chairTileSheet}
                        setSelectedIndex={handleTileSelection}
                        gridColumns={8}
                    />
                );
            case "decorations":
                return (
                    <TileSheetViewer
                        tileSheetSrc={decorationsTileSheet}
                        setSelectedIndex={handleTileSelection}
                        gridColumns={8}
                    />
                );
        }
    }

    if (scene && scene.scene.key === "Game") {
        return (
            <>
                <div
                    className="build-window"
                    id="ui-layer"
                    style={{ display: isOpen ? "inherit" : "none" }}
                >
                    <button
                        className="inner-chat-toggle game-button"
                        onClick={() => setIsOpen(false)}
                    >
                        X
                    </button>
                    <button className="game-button" onClick={toggleBuildMode}>
                        {buildMode ? "EXIT BUILD MODE" : "ENTER BUILD MODE"}
                    </button>
                    {buildMode ? (
                        <>
                            <div className="build-menu">
                                <select
                                    className="game-button"
                                    value={selectedLayer}
                                    onChange={(e) => {
                                        handleLayerSelection(e.target.value);
                                    }}
                                >
                                    <option value="floor">floors</option>
                                    <option value="walls">walls</option>
                                    <option value="chairs">chairs</option>
                                    <option value="furniture">furniture</option>
                                    <option value="decorations">
                                        decorations
                                    </option>
                                </select>
                                <div className="build-menu-buttons">
                                    <button
                                        className="game-button"
                                        onClick={() => handleTileSelection(-1)}
                                    >
                                        eraser
                                    </button>
                                    <button
                                        className="game-button"
                                        onClick={() =>
                                            handleSaveBuildModeMapState()
                                        }
                                    >
                                        save
                                    </button>
                                    <button
                                        className="game-button"
                                        onClick={() =>
                                            handleResetBuildModeMapState()
                                        }
                                    >
                                        reset
                                    </button>
                                    <button
                                        className="game-button"
                                        onClick={() =>
                                            handleSubmitBuildModeMapState()
                                        }
                                    >
                                        submit
                                    </button>
                                </div>
                            </div>
                            {handleTileSheetViewer()}
                        </>
                    ) : null}
                </div>
                <button
                    className="build-toggle game-button"
                    style={{ display: isOpen ? "none" : "block" }}
                    onClick={() => setIsOpen(true)}
                    id="ui-layer"
                    title="build mode"
                >
                    <img
                        className="game-icon"
                        src={homeIcon}
                        width={25}
                        height={25}
                    />
                </button>
            </>
        );
    }
};

export default BuildWindow;

