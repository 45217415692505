import { Scene } from "phaser";
import Network from "../../network/Network";
import { EventBus, MyEvents } from "../EventBus";
import { usePlayerContextManager } from "../../context/PlayerContextManager";

export class Preloader extends Scene {
    constructor() {
        super("Preloader");
        this.network = null;
        this.mapData = null;
        EventBus.on(MyEvents.CONNECTED, (data) => {
            this.connected = true;
            this.otherPlayers = data;
        });
    }

    init() {
        this.network = Network.getInstance();
    }

    preload() {
        // Load your assets here
        this.load.spritesheet(
            "character",
            "assets/sprites/Beta Sprite-Sheet.png",
            {
                frameWidth: 32,
                frameHeight: 32,
            },
        );

        this.load.spritesheet(
            "furnitureSprites",
            "assets/tilemaps/furniture.png",
            {
                frameWidth: 32,
                frameHeight: 32,
            },
        );

        this.load.spritesheet("chairsSprites", "assets/tilemaps/chairs.png", {
            frameWidth: 32,
            frameHeight: 32,
        });
        this.load.spritesheet(
            "decorationsSprites",
            "assets/tilemaps/decorations.png",
            {
                frameWidth: 32,
                frameHeight: 32,
            },
        );

        this.load.image("floor", "assets/tilemaps/floor.png");
        this.load.image("walls", "assets/tilemaps/walls.png");
        this.load.image("furniture", "assets/tilemaps/furniture.png");
        this.load.image("chairs", "assets/tilemaps/chairs.png");
        this.load.image("decorations", "assets/tilemaps/decorations.png");

        this.load.tilemapTiledJSON("map", "assets/maps/trenches1.json");
        this.load.on("filecomplete-tilemapJSON-map", (key, type, data) => {
            let mapData = {};
            mapData.floor = data.layers[0].data;
            mapData.walls = data.layers[2].data;
            mapData.furniture = data.layers[5].data;
            mapData.decorations = data.layers[6].data;
            this.mapData = mapData;
        });
    }

    create() {
        const checkReady = () => {
            if (this.connected) {
                this.scene.start("Game", {
                    network: Network.getInstance(),
                    otherPlayers: this.otherPlayers,
                    mapData: this.mapData,
                });
            } else {
                // Retry after a short delay
                this.time.delayedCall(100, checkReady, [], this);
            }
        };

        checkReady();
    }
}

