import React, { useState, useEffect, useRef } from "react";

const TileSheetViewer = ({ tileSheetSrc, setSelectedIndex, gridColumns }) => {
    const [selectedTile, setSelectedTile] = useState({ x: 0, y: 0 });
    const [gridRows, setGridRows] = useState(0);
    const tileSheetRef = useRef(null);

    const TILE_SIZE = 32;
    const GRID_COLUMNS = gridColumns; // Adjust as needed for your tile sheet

    useEffect(() => {
        const tileSheetImg = new Image();
        tileSheetImg.src = tileSheetSrc;
        tileSheetImg.onload = () => {
            setGridRows(Math.floor(tileSheetImg.height / TILE_SIZE));
        };
    }, [tileSheetSrc, TILE_SIZE]);

    const Tile = ({ x, y, tileSheetSrc, sTile, onClick }) => {
        return (
            <div
                className="tile"
                style={{
                    width: `${TILE_SIZE}px`,
                    height: `${TILE_SIZE}px`,
                    backgroundImage: `url(${tileSheetSrc})`,
                    backgroundPosition: `-${x * TILE_SIZE}px -${y * TILE_SIZE}px`,
                    cursor: "pointer",
                    border:
                        sTile.x === x && sTile.y === y
                            ? "2px dashed greenyellow"
                            : "none", // Add border if selected
                    margin: sTile.x === x && sTile.y === y ? "0px" : "2px",
                }}
                onMouseEnter={(e) => {
                    e.target.style.border = "2px dashed white";
                    e.target.style.margin = "0px";
                }}
                onMouseLeave={(e) => {
                    e.target.style.border =
                        sTile.x === x && sTile.y === y
                            ? "2px dashed greenyellow"
                            : "none";
                    e.target.style.margin =
                        sTile.x === x && sTile.y === y ? "0px" : "2px";
                }}
                onClick={() => onClick(x, y)}
            />
        );
    };

    const calcTileIndex = (x, y) => {
        return y * GRID_COLUMNS + x + 1;
    };

    const handleTileClick = (x, y) => {
        setSelectedTile({ x, y });
        setSelectedIndex(calcTileIndex(x, y));
    };

    const renderTiles = () => {
        const tiles = [];
        for (let y = 0; y < gridRows; y++) {
            for (let x = 0; x < GRID_COLUMNS; x++) {
                tiles.push(
                    <Tile
                        key={`${x}-${y}`}
                        x={x}
                        y={y}
                        tileSheetSrc={tileSheetSrc}
                        sTile={selectedTile}
                        onClick={handleTileClick}
                    />,
                );
            }
        }
        return tiles;
    };

    return (
        <div
            ref={tileSheetRef}
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${GRID_COLUMNS}, ${TILE_SIZE}px)`,
                gap: "0.1rem",
                width: "100%",
                overflowY: "scroll",
                justifyContent: "center",
            }}
        >
            {renderTiles()}
        </div>
    );
};

export default TileSheetViewer;

