import { useState, useEffect } from "react";
import { usePlayerContext } from "../context/PlayerContext";
import { EventBus, MyEvents } from "../game/EventBus";
import soundOn from "../images/Speaker-0.png";
import soundOff from "../images/Speaker-Crossed.png";
import playersIcon from "../images/People.png";
import micOn from "../images/Microphone.png";
import micOff from "../images/Microphone-Crossed.png";

const PlayerLabel = ({ player, mutePlayer }) => {
    const [isMuted, setIsMuted] = useState(player.config.audioMuted);
    const [speaking, setSpeaking] = useState(false);
    useEffect(() => {
        const handleSpeaking = (data) => {
            if (data.peerId === player.inGameID) {
                setSpeaking(data.isSpeaking);
            }
        };

        EventBus.on(MyEvents.PLAYER_SPEAKING, handleSpeaking);

        return () => {
            EventBus.off(MyEvents.PLAYER_SPEAKING, handleSpeaking);
        };
    }, []);

    const handleMute = () => {
        mutePlayer(player.inGameID, !isMuted);
        setIsMuted(!isMuted);
    };

    const handleName = (name) => {
        if (name.length > 15) {
            return (
                name.substring(0, 6) + "..." + name.substring(name.length - 6)
            );
        }
        return name;
    };

    return (
        <div className="player-label">
            <button className="game-button" onClick={handleMute}>
                <img
                    src={isMuted ? soundOff : soundOn}
                    width={15}
                    height={15}
                />
            </button>
            <p className={`player-name ${speaking ? "speaking" : ""}`}>
                {handleName(player.name)}
            </p>
        </div>
    );
};

const PlayerWindow = ({ scene, isOpen, setIsOpen }) => {
    const playerContext = usePlayerContext();
    const [muted, setMuted] = useState(false);
    const [allMuted, setAllMuted] = useState(false);

    useEffect(() => {
        const storedMuted = localStorage.getItem("selfMuted");
        setMuted(storedMuted === "true");
    }, []);

    if (scene && scene.scene.key === "Game") {
        const toggleMuteSelf = () => {
            setMuted(!muted);
            localStorage.setItem("selfMuted", !muted.toString());
            scene.network.muteSelf(!muted);
        };

        const toggleMuteAll = () => {
            setAllMuted(!allMuted);
            scene.network.muteAll(!allMuted);
        };

        const mutePlayer = (playerId, value) => {
            scene.network.mutePeer(playerId, value);
            playerContext.setPlayerConfig(playerId, { audioMuted: value });
        };

        const renderPlayerLabels = () => {
            return playerContext.players.map((player) => (
                <PlayerLabel
                    key={player.inGameID}
                    player={player}
                    mutePlayer={mutePlayer}
                />
            ));
        };

        return (
            <>
                <div
                    className="player-window"
                    id="ui-layer"
                    style={{ display: isOpen ? "inherit" : "none" }}
                >
                    <button
                        className="inner-chat-toggle game-button"
                        onClick={() => setIsOpen(false)}
                    >
                        X
                    </button>
                    {renderPlayerLabels()}
                </div>
                <button
                    className="players-toggle game-button"
                    style={{ display: isOpen ? "none" : "block" }}
                    onClick={() => setIsOpen(true)}
                    id="ui-layer"
                    title="players"
                >
                    <img
                        className="game-icon"
                        src={playersIcon}
                        width={25}
                        height={25}
                    />
                </button>
                <button
                    className="sound-toggle game-button"
                    style={{ display: isOpen ? "none" : "block" }}
                    id="ui-layer"
                    title="mute everyone else"
                    onClick={toggleMuteAll}
                >
                    <img
                        className="game-icon"
                        src={allMuted ? soundOff : soundOn}
                        width={25}
                        height={25}
                    />
                </button>
                <button
                    className="mic-toggle game-button"
                    style={{ display: isOpen ? "none" : "block" }}
                    id="ui-layer"
                    title="mute self"
                    onClick={toggleMuteSelf}
                >
                    <img
                        className="game-icon"
                        src={muted ? micOff : micOn}
                        width={25}
                        height={25}
                    />
                </button>
            </>
        );
    }
};

export default PlayerWindow;

