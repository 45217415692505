import { Boot } from "./scenes/Boot";
import { Game } from "./scenes/Game";
import Phaser from "phaser";
import { Preloader } from "./scenes/Preloader";
import { Kicked } from "./scenes/Kicked";
// Find out more information about the Game Config at:
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
    type: Phaser.AUTO,
    pixelArt: true, // Prevent pixel art from becoming blurred when scaled.
    scale: {
        mode: Phaser.Scale.ScaleModes.RESIZE,
        width: window.innerWidth,
        height: window.innerHeight,
    },
    //autoFocus: true,
    parent: "game-container",
    physics: {
        default: "arcade",
        arcade: {
            gravity: { y: 0 },
            //debug: true,
        },
    },
    backgroundColor: "#028af8",
    fps: {
        target: 60,
        forceSetTimeOut: true,
    },
    scene: [Boot, Preloader, Game, Kicked],
};

const StartGame = (parent) => {
    return new Phaser.Game({ ...config, parent });
};

export default StartGame;

