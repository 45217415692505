import { Scene } from "phaser";

export class Kicked extends Scene {
    constructor() {
        super("Kicked");
    }

    preload() {
        //  The Boot Scene is typically used to load in any assets you require for your Preloader, such as a game logo or background.
        //  The smaller the file size of the assets, the better, as the Boot Scene itself has no preloader.
        //this.load.image('background', 'assets/bg.png');
    }

    create() {
        const textStyle = {
            font: "32px Arial",
            fill: "#ffffff",
            align: "center",
        };
        const text = this.add.text(
            this.cameras.main.centerX,
            this.cameras.main.centerY,
            "You have been kicked from the game. please reconnect",
            textStyle,
        );
        text.setOrigin(0.5, 0.5);
    }
}

