import { Joystick } from "react-joystick-component";
const JoystickComponent = ({ scene }) => {
    const angleToDirections = (angle) => {
        let outObj = {
            left: false,
            right: false,
            up: false,
            down: false,
        };
        angle = (angle + 360) % 360;

        if (angle > 22.5 && angle <= 67.5) {
            outObj.down = true;
            outObj.right = true;
        } else if (angle > 67.5 && angle <= 112.5) {
            outObj.down = true;
        } else if (angle > 112.5 && angle <= 157.5) {
            outObj.down = true;
            outObj.left = true;
        } else if (angle > 157.5 && angle <= 202.5) {
            outObj.left = true;
        } else if (angle > 202.5 && angle <= 247.5) {
            outObj.left = true;
            outObj.up = true;
        } else if (angle > 247.5 && angle <= 292.5) {
            outObj.up = true;
        } else if (angle > 292.5 && angle <= 337.5) {
            outObj.up = true;
            outObj.right = true;
        } else {
            outObj.right = true;
        }
        return outObj;
    };

    if (scene && scene.scene.key === "Game") {
        return (
            <div className="joystick" id="ui-layer">
                <Joystick
                    size={100}
                    sticky={false}
                    baseColor="rgba(0, 0, 0, 0.5)"
                    stickColor="rgba(0, 0, 0, 0.5)"
                    move={(event) => {
                        const x1 = 0;
                        const y1 = event.y ?? 0;
                        const x2 = event.x ?? 0;
                        const y2 = 0;
                        var deltaX = x2 - x1; // distance between joystick and center
                        var deltaY = y2 - y1; // distance between joystick and center
                        var rad = Math.atan2(deltaY, deltaX); // In radians
                        var deg = (rad * 180) / Math.PI; // In degrees
                        var direction = angleToDirections(deg); // Convert degrees to direction
                        scene.handleJoystickMovement({
                            isMoving: true,
                            direction,
                        });
                    }}
                    stop={() => {
                        scene.handleJoystickMovement({
                            isMoving: false,
                            direction: {
                                up: false,
                                down: false,
                                left: false,
                                right: false,
                            },
                        });
                    }}
                ></Joystick>
            </div>
        );
    }
};

export default JoystickComponent;

