import Phaser from "phaser";
import Player from "./Player";

export default class MyPlayer extends Player {
    constructor(scene, x, y, texture, name, id) {
        super(scene, x, y, texture, name, id);

        this.registerCursors(scene);

        this.joystickMovement = {
            isMoving: false,
            direction: {
                up: false,
                down: false,
                left: false,
                right: false,
            },
        };

        this.playerView = this.scene.add
            .container(this.x, this.y)
            .setDepth(this.y);

        this.scene.physics.world.enable(this.playerView);

        this.playerView.body.setSize(
            this.bodySize[0] * 1.5,
            this.bodySize[1] * 1.5,
        );

        this.playerSelectedItem = null;
        this.playerSelectedItemTime = null;
        this.hoverText = null;
        this.isInBuildMode = false;
    }

    registerCursors(scene) {
        this.cursors = scene.input.keyboard.addKeys({
            up: Phaser.Input.Keyboard.KeyCodes.W,
            down: Phaser.Input.Keyboard.KeyCodes.S,
            left: Phaser.Input.Keyboard.KeyCodes.A,
            right: Phaser.Input.Keyboard.KeyCodes.D,
        });
        this.scene.input.keyboard.disableGlobalCapture();
    }

    update() {
        if (!this.isInBuildMode) {
            this.handleMovement();
            this.handleItemOverlap();
        }
    }

    handleMovement() {
        const { left, right, up, down } = this.cursors;
        const body = this.body;

        let joystickLeft = false;
        let joystickRight = false;
        let joystickUp = false;
        let joystickDown = false;

        if (this.joystickMovement?.isMoving) {
            joystickLeft = this.joystickMovement.direction.left;
            joystickRight = this.joystickMovement.direction.right;
            joystickUp = this.joystickMovement.direction.up;
            joystickDown = this.joystickMovement.direction.down;
        }

        // Reset velocity
        let velocityX = 0;
        let velocityY = 0;

        if (left.isDown || joystickLeft) velocityX = -this.moveSpeed;
        if (right.isDown || joystickRight) velocityX = this.moveSpeed;
        if (up.isDown || joystickUp) velocityY = -this.moveSpeed;
        if (down.isDown || joystickDown) velocityY = this.moveSpeed;

        if (velocityX > 0) {
            this.play("walk-right", true);
            this.lastDirection = "right";
        } else if (velocityX < 0) {
            this.play("walk-left", true);
            this.lastDirection = "left";
        } else if (velocityY > 0) {
            this.play("walk-down", true);
            this.lastDirection = "down";
        } else if (velocityY < 0) {
            this.play("walk-up", true);
            this.lastDirection = "up";
        } else {
            this.play(`idle-${this.lastDirection}`, true);
        }

        body.setVelocity(velocityX, velocityY);
        body.velocity.setLength(this.moveSpeed);
        //this.playerContainer.body.setVelocity(velocityX, velocityY);
        //this.playerContainer.body.velocity.setLength(this.moveSpeed);
        this.scene.network.movePlayer(this.x, this.y, this.lastDirection);

        this.playerContainer.x = body.x; //- this.containerOffset[0];
        this.playerContainer.y = body.y; //- this.containerOffset[1];
        this.playerView.x = body.x;
        this.playerView.y = body.y;

        if (this.lastDirection == "down") {
            this.playerView.y = this.playerView.y + 10;
        } else if (this.lastDirection == "up") {
            this.playerView.y = this.playerView.y - 10;
        } else if (this.lastDirection == "left") {
            this.playerView.x = this.playerView.x - 10;
        } else if (this.lastDirection == "right") {
            this.playerView.x = this.playerView.x + 10;
        }

        /*if (velocityX !== 0 || velocityY !== 0)
            if (
                !this.lastMoveTime ||
                this.scene.time.now - this.lastMoveTime > 20
            ) {
                this.scene.network.movePlayer(
                    this.x,
                    this.y,
                    this.lastDirection,
                );
                this.lastMoveTime = this.scene.time.now;
            } else if (velocityX === 0 && velocityY === 0) {
                if (this.lastMoveTime !== null) {
                    this.scene.network.movePlayer(
                        this.x,
                        this.y,
                        this.lastDirection,
                    );
                    this.lastMoveTime = null;
                }
            }*/
    }

    handleItemOverlap() {
        if (this.playerSelectedItemTime && this.hoverText) {
            if (this.scene.time.now - this.playerSelectedItemTime > 100) {
                this.playerSelectedItem = null;
                this.playerSelectedItemTime = null;
                this.hoverText.destroy();
                this.hoverText = null;
            }
        } else if (this.playerSelectedItem && !this.hoverText) {
            this.hoverText = this.scene.add
                .text(
                    this.playerSelectedItem.x,
                    this.playerSelectedItem.y,
                    "press F",
                    {
                        fontFamily: "Arial",
                        fontSize: "8px",
                        color: "#ffffff",
                        backgroundColor: "#000000",
                        padding: { x: 5, y: 2 },
                    },
                )
                .setOrigin(0.5)
                .setResolution(10)
                .setAlpha(0.5)
                .setDepth(9999);
        }
    }

    updateJoystickMovement(movement) {
        this.joystickMovement = movement;
    }
}

