import React, { createContext, useReducer, useContext } from "react";

const initialState = {
    players: [],
};

const ADD_PLAYER = "ADD_PLAYER";
const ADD_PLAYERS = "ADD_PLAYERS";
const REMOVE_PLAYER = "REMOVE_PLAYER";
const EDIT_PLAYER = "EDIT_PLAYER";
const SET_PLAYER_CONFIG = "SET_PLAYER_CONFIG";
function playerReducer(state, action) {
    switch (action.type) {
        case ADD_PLAYER:
            return { ...state, players: [...state.players, action.payload] };
        case ADD_PLAYERS:
            return { ...state, players: [...state.players, ...action.payload] };
        case REMOVE_PLAYER:
            return {
                ...state,
                players: state.players.filter(
                    (player) => player.inGameID !== action.payload,
                ),
            };
        case EDIT_PLAYER:
            return {
                ...state,
                players: state.players.map((player) =>
                    player.inGameID === action.payload.inGameID
                        ? { ...player, ...action.payload.updates }
                        : player,
                ),
            };
        case SET_PLAYER_CONFIG:
            return {
                ...state,
                players: state.players.map((player) =>
                    player.inGameID === action.payload.inGameID
                        ? {
                              ...player,
                              config: {
                                  ...player.config,
                                  ...action.payload.config,
                              },
                          }
                        : player,
                ),
            };
        default:
            return state;
    }
}

const PlayerContext = createContext();

export function PlayerProvider({ children }) {
    const [state, dispatch] = useReducer(playerReducer, initialState);

    const getConfigFromLocalStorage = (accountId) => {
        const storedConfigs =
            JSON.parse(localStorage.getItem("playerConfigs")) || {};
        return storedConfigs[accountId] || {};
    };

    const setConfigInLocalStorage = (accountId, config) => {
        const storedConfigs =
            JSON.parse(localStorage.getItem("playerConfigs")) || {};
        storedConfigs[accountId] = { ...storedConfigs[accountId], ...config };
        localStorage.setItem("playerConfigs", JSON.stringify(storedConfigs));
    };

    const addPlayer = (player) => {
        const existingConfig = getConfigFromLocalStorage(player.accountId);
        const playerWithConfig = {
            ...player,
            config: { ...player.config, ...existingConfig },
        };
        dispatch({ type: ADD_PLAYER, payload: playerWithConfig });
    };

    const addPlayers = (players) => {
        const playersWithConfigs = players.map((player) => {
            const existingConfig = getConfigFromLocalStorage(player.accountId);
            return {
                ...player,
                config: { ...player.config, ...existingConfig },
            };
        });
        dispatch({ type: ADD_PLAYERS, payload: playersWithConfigs });
    };

    const removePlayer = (playerId) => {
        dispatch({ type: REMOVE_PLAYER, payload: playerId });
    };

    const editPlayer = (playerId, updates) => {
        dispatch({ type: EDIT_PLAYER, payload: { id: playerId, updates } });
    };

    const getPlayerById = (playerId) => {
        return state.players.find((player) => player.id === playerId);
    };

    const getAllPlayers = () => {
        return state.players;
    };

    const setPlayerConfig = (playerId, config) => {
        const player = state.players.find((p) => p.id === playerId);
        if (player) {
            setConfigInLocalStorage(player.accountId, config);
            dispatch({
                type: SET_PLAYER_CONFIG,
                payload: { config },
            });
        }
    };

    return (
        <PlayerContext.Provider
            value={{
                players: state.players,
                addPlayer,
                addPlayers,
                removePlayer,
                editPlayer,
                getPlayerById,
                getAllPlayers,
                setPlayerConfig,
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
}

export function usePlayerContext() {
    return useContext(PlayerContext);
}

