import Phaser from "phaser";

// Used to emit events between React components and Phaser scenes
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Events.EventEmitter
export const EventBus = new Phaser.Events.EventEmitter();

export const MyEvents = {
    CONNECTED: "onMyPlayerConnected",
    PLAYER_JOINED: "onPlayerJoined",
    PLAYER_MOVED: "onPlayerMoved",
    PLAYER_LEFT: "onPlayerLeft",
    PLAYER_SPEAKING: "onPlayerSpeaking",
    KICKED: "onKicked",
    MESSAGE: "onUserMessage",
    MY_MESSAGE: "onMyMessage",
    WEBRTC: "webrtc",
    WEBRTC_CONNECTED: "onWebrtcConnected",
    BUILD_TILE_SELECTED: "onBuildTileSelected",
    SAVE_BUILD_MODE_MAP_STATE: "onSaveBuildModeMapState",
    RESET_BUILD_MODE_MAP_STATE: "onResetBuildModeMapState",
};

