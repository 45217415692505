import { config } from "../conf";

export const handleAuth = async () => {
    try {
        // Request the user's Ethereum address
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        const account = accounts[0];

        // Call the auth endpoint to get a nonce with the wallet address
        const nonceResponse = await fetch(
            `${config.authURL}${config.authGetNoncePath}${account}`,
        );
        const { message, nonce } = await nonceResponse.json();

        // Use personal_sign to sign the message
        const signature = await window.ethereum.request({
            method: "personal_sign",
            params: [message, account],
        });

        // Verify the signature to get the JWT
        const verifyResponse = await fetch(
            `${config.authURL}${config.authVerifySignaturePath}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    wallet_address: account,
                    nonce,
                    signature,
                }),
            },
        );

        const { token, user, exp } = await verifyResponse.json();
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("ut", token);
        localStorage.setItem("exp", exp);
    } catch (error) {
        console.error("Authentication error:", error);
    }
};

