import { MyEvents, EventBus } from "../game/EventBus";
import { WebRTCPeerManager } from "./WebRTCPeerManager";
import { config } from "../conf";
class Network {
    constructor(playerContext, token, micEnabled, localStream) {
        if (Network.instance) {
            return Network.instance;
        }

        this.nano = window.nano;
        this.init(token);
        this.myInGameID = null;
        this.myDisplayName = null;
        this.myAccountID = null;
        this.webRTCPeerManager = null;
        this.playerContext = playerContext;
        this.micEnabled = micEnabled;
        this.localStream = localStream;
        Network.instance = this;
    }

    static getInstance() {
        return Network.instance;
    }

    init(token) {
        this.nano.init(
            {
                host: config.gameserverHost,
                port: config.gameserverPort,
                path: config.gameserverPath,
                scheme: config.gameserverScheme,
                user: {
                    user: token,
                },
            },
            () => {
                console.log("Connected to server");
                this.setupMessageHandlers();
                this.joinGame();
            },
        );
    }

    setupMessageHandlers() {
        this.nano.on(
            MyEvents.PLAYER_JOINED,
            this.handlePlayerJoined.bind(this),
        );
        this.nano.on(MyEvents.PLAYER_MOVED, this.handlePlayerMoved.bind(this));
        this.nano.on(MyEvents.PLAYER_LEFT, this.handlePlayerLeft.bind(this));
        this.nano.on(MyEvents.MESSAGE, (data) => {
            this.handleMessage(data);
        });
        this.nano.on(MyEvents.KICKED, this.handleKicked.bind(this));
        this.nano.on("disconnect", this.handleKicked.bind(this));

        this.nano.on("webrtc", (data) => {
            switch (data.type) {
                case "offer":
                    this.handleIncomingOffer(data.from, data.payload);
                    break;
                case "answer":
                    this.handleIncomingAnswer(data.from, data.payload);
                    break;
                case "ice-candidate":
                    this.handleIncomingIceCandidate(data.from, data.payload);
                    break;
            }
        });
    }

    handlePlayerJoined(data) {
        EventBus.emit(MyEvents.PLAYER_JOINED, data);
        this.playerContext.addPlayer(data);
    }

    handlePlayerMoved(data) {
        const { inGameID, x, y, anim } = data;
        EventBus.emit(MyEvents.PLAYER_MOVED, { inGameID, x, y, anim });
    }

    handlePlayerLeft(data) {
        const { inGameID } = data;
        EventBus.emit(MyEvents.PLAYER_LEFT, { inGameID });
        this.playerContext.removePlayer(inGameID);
    }

    handleMessage(data) {
        const { name, content, inGameID } = data;
        EventBus.emit(MyEvents.MESSAGE, { name, content, inGameID });
    }

    handleKicked() {
        EventBus.emit(MyEvents.KICKED);
        this.disconnect();
        this.webRTCPeerManager.disconnectFromAll();
    }

    notifySpeakingState(peerId, isSpeaking) {
        EventBus.emit(MyEvents.PLAYER_SPEAKING, { peerId, isSpeaking });
    }

    joinGame() {
        this.request("game.join", {}, (data) => {
            if (data.result == "success") {
                console.log("Successfully joined game:", data);
                this.myInGameID = data.inGameID;
                let name = data.name;
                if (name.length > 15) {
                    name =
                        name.substring(0, 6) +
                        "..." +
                        name.substring(name.length - 6);
                }
                this.myDisplayName = name;
                this.myAccountID = data.accountID;
                this.webRTCPeerManager = new WebRTCPeerManager(
                    this.myInGameID,
                    this.localStream,
                    this.notifySpeakingState,
                );

                // set handlers
                this.webRTCPeerManager.onIceCandidate = (peerId, candidate) => {
                    // Send the ICE candidate through signaling server
                    nano.notify("game.webrtc", {
                        type: "ice-candidate",
                        to: peerId,
                        from: this.myInGameID,
                        connType: 0,
                        payload: candidate,
                    });
                };

                // Set up connection event handlers
                this.webRTCPeerManager.onPeerConnected((peerId) => {
                    console.log(`Connected to ${peerId}!`);
                    // You can now send messages with:
                    this.webRTCPeerManager.sendToPeer(
                        peerId,
                        JSON.stringify({
                            type: "connection",
                            message: "connection established",
                        }),
                    );
                });

                this.webRTCPeerManager.onPeerDisconnected((peerId) => {
                    console.log(`Disconnected from ${peerId}`);
                });

                this.webRTCPeerManager.onMessage((peerId, message) => {
                    const data = JSON.parse(message);
                    switch (data.type) {
                        case "move":
                            EventBus.emit(MyEvents.PLAYER_MOVED, {
                                inGameID: peerId,
                                ...data.data,
                            });
                            break;
                        case "connection":
                            console.log("Connection established with", peerId);
                            break;
                    }
                });

                data.members.forEach((member) => {
                    this.initiateConnection(member.inGameID);
                });

                EventBus.emit(MyEvents.CONNECTED, data.members);
                this.playerContext.addPlayers(data.members);
            } else {
                console.error("Failed to join game:", data);
            }
        });
    }

    // 1. When YOU want to connect to someone:
    async initiateConnection(targetPeerId) {
        // This creates the offer
        const { offer } = await this.webRTCPeerManager.connectToPeer(
            targetPeerId,
            this.micEnabled,
        );

        // Send this offer through your signaling server
        nano.notify("game.webrtc", {
            type: "offer",
            to: targetPeerId,
            from: this.myInGameID,
            connType: 0,
            payload: offer,
        });
    }

    // 2. When SOMEONE wants to connect to you,
    // and you receive their offer from signaling server:
    async handleIncomingOffer(fromPeerId, offer) {
        // This creates the answer
        const { answer } = await this.webRTCPeerManager.handleConnectionRequest(
            fromPeerId,
            offer,
        );

        // Send the answer back through signaling server
        nano.notify("game.webrtc", {
            type: "answer",
            to: fromPeerId,
            from: this.myInGameID,
            connType: 0,
            payload: answer,
        });
    }

    // 3. When you receive an answer to your offer:
    handleIncomingAnswer(fromPeerId, answer) {
        this.webRTCPeerManager.handleAnswer(fromPeerId, answer);
    }

    // 5. When you receive an ICE candidate from signaling server:
    handleIncomingIceCandidate(fromPeerId, candidate) {
        this.webRTCPeerManager.handleIceCandidate(fromPeerId, candidate);
    }

    movePlayer(x, y, anim) {
        this.webRTCPeerManager.broadcast(
            JSON.stringify({ type: "move", data: { x, y, anim } }),
        );
    }

    async sendMessage(message) {
        return new Promise((resolve) => {
            this.nano.request(
                "game.message",
                { name: this.myDisplayName, content: message },
                (data) => {
                    if (data.code === 0) {
                        resolve({ success: true });
                    } else {
                        reject({ success: false, error: data.result });
                    }
                },
            );
        });
    }

    request(route, msg, callback) {
        this.nano.request(route, msg, (response) => {
            if (callback) callback(response);
        });
    }

    notify(route, msg) {
        this.nano.notify(route, msg);
    }

    disconnect() {
        this.nano.disconnect();
    }

    muteSelf(value) {
        this.webRTCPeerManager.muteLocalMicrophone(value);
    }

    muteAll(value) {
        this.webRTCPeerManager.setAllMuted(value);
    }

    mutePeer(peerId, value) {
        this.webRTCPeerManager.setPeerAudioMuted(peerId, value);
    }
}

export default Network;

