export const createCharacterAnims = (scene) => {
    const animsFrameRate = 15;

    const directions = ["down", "up", "right", "left"];
    const frameStartIndices = [0, 3, 6, 9];

    directions.forEach((direction, index) => {
        const startFrame = frameStartIndices[index];

        // Idle animation for each direction
        scene.anims.create({
            key: `idle-${direction}`,
            frames: [{ key: "character", frame: startFrame }],
            repeat: -1,
            frameRate: animsFrameRate,
        });

        // Walking animation for each direction
        scene.anims.create({
            key: `walk-${direction}`,
            frames: [
                { key: "character", frame: startFrame },
                { key: "character", frame: startFrame + 1 },
                { key: "character", frame: startFrame },
                { key: "character", frame: startFrame + 2 },
            ],
            repeat: -1,
            frameRate: animsFrameRate,
        });
    });
};

