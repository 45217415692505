import { EventBus, MyEvents } from "../game/EventBus";

export class WebRTCPeerManager {
    constructor(myPeerId, localStream, notifySpeakingFn) {
        this.myPeerId = myPeerId;
        this.peers = new Map();
        this.incomingTrackIds = new Map();
        this.onMessageCallbacks = new Set();
        this.onPeerConnectedCallbacks = new Set();
        this.onPeerDisconnectedCallbacks = new Set();
        this.localStream = localStream;
        this.audioContext = null;
        this.promptedMic = false;
        this.remoteAudioStream = new MediaStream();
        this.notifySpeakingState = notifySpeakingFn;

        this.audioElement = document.createElement("audio");
        this.audioElement.srcObject = this.remoteAudioStream;
        this.audioElement.autoplay = true;
        this.audioElement.style.display = "none";
        document.body.appendChild(this.audioElement);
    }

    getPeerConnection(peerId) {
        if (!this.peers.has(peerId)) {
            const peerConnection = new RTCPeerConnection({
                iceServers: [
                    { urls: "stun:stun.l.google.com:19302" },
                    { urls: "stun:stun1.l.google.com:19302" },
                    { urls: "stun:stun2.l.google.com:19302" },
                ],
                iceTransportPolicy: "all", // Try all possible transport methods
                bundlePolicy: "max-bundle",
                rtcpMuxPolicy: "require",
                sdpSemantics: "unified-plan",
                iceCandidatePoolSize: 10,
            });

            const peer = {
                id: peerId,
                connection: peerConnection,
                dataChannel: null,
                isInitiator: false,
                audioSourceNode: null,
                gainNode: null,
                analyser: null,
            };

            peerConnection.onconnectionstatechange = () => {
                switch (peerConnection.connectionState) {
                    case "connected":
                        this.notifyPeerConnected(peerId);
                        break;
                    case "disconnected":
                    case "failed":
                    case "closed":
                        this.notifyPeerDisconnected(peerId);
                        this.peers.delete(peerId);
                        this.incomingTrackIds.delete(peerId);
                        break;
                }
            };

            peerConnection.ondatachannel = (event) => {
                this.setupDataChannel(peerId, event.channel);
            };

            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    this.onIceCandidate(peerId, event.candidate);
                }
            };

            peerConnection.ontrack = (event) => {
                const stream = event.streams[0];
                if (stream.id !== this.localStream?.id) {
                    const trackIds = stream
                        .getTracks()
                        .map((track) => track.id);
                    this.incomingTrackIds.set(peerId, trackIds);
                    this.setupAudioStream(peerId, stream);
                }
            };

            this.peers.set(peerId, peer);
        }

        return this.peers.get(peerId);
    }

    async initializeAudioContext() {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext ||
                window.webkitAudioContext)();
            if (this.audioContext.state === "suspended") {
                await this.audioContext.resume();
            }
        }
        return this.audioContext;
    }

    async setupAudioStream(peerId, stream) {
        const peer = this.peers.get(peerId);
        if (!peer) return;

        try {
            await this.initializeAudioContext();

            if (peer.audioSourceNode) peer.audioSourceNode.disconnect();
            if (peer.gainNode) peer.gainNode.disconnect();
            if (peer.analyser) peer.analyser.disconnect();

            const audioSourceNode =
                this.audioContext.createMediaStreamSource(stream);
            const gainNode = this.audioContext.createGain();
            const analyser = this.audioContext.createAnalyser();

            analyser.fftSize = 512;
            analyser.smoothingTimeConstant = 0.8;
            gainNode.gain.value = 0.8;

            audioSourceNode.connect(gainNode);
            gainNode.connect(analyser);
            gainNode.connect(this.audioContext.destination);

            peer.audioSourceNode = audioSourceNode;
            peer.gainNode = gainNode;
            peer.analyser = analyser;
            peer.isSpeaking = false;

            stream.getTracks().forEach((track) => {
                this.remoteAudioStream.addTrack(track);
            });

            this.monitorSpeaking(peerId);
        } catch (error) {
            console.error(`Error setting up audio for peer ${peerId}:`, error);
        }
    }

    setupDataChannel(peerId, channel) {
        const peer = this.peers.get(peerId);
        if (!peer) return;

        peer.dataChannel = channel;

        channel.onopen = () => {
            console.log(`Data channel opened with peer ${peerId}`);
        };

        channel.onmessage = (event) => {
            this.notifyMessage(peerId, event.data);
        };

        channel.onerror = (error) => {
            console.error(`Data channel error with peer ${peerId}:`, error);
        };

        channel.onclose = () => {
            console.log(`Data channel closed with peer ${peerId}`);
        };
    }

    async connectToPeer(peerId, micEnabled = false) {
        const peer = this.getPeerConnection(peerId);
        peer.isInitiator = true;

        peer.connection.addTransceiver("audio", {
            direction: "sendrecv",
            streams: [],
        });

        const dataChannel = peer.connection.createDataChannel(
            "unreliableChannel",
            {
                ordered: false,
                maxRetransmits: 0,
                negotiated: false, // Ensure auto-negotiation
                protocol: "webrtc", // Explicit protocol
            },
        );
        this.setupDataChannel(peerId, dataChannel);

        try {
            if (!this.localStream) {
                if (micEnabled) {
                    this.localStream =
                        await navigator.mediaDevices.getUserMedia({
                            audio: {
                                echoCancellation: true,
                                noiseSuppression: true,
                                autoGainControl: true,
                            },
                        });
                    this.promptedMic = true;
                } else {
                    await this.initializeAudioContext();
                    const oscillator = this.audioContext.createOscillator();
                    const destination =
                        this.audioContext.createMediaStreamDestination();
                    oscillator.connect(destination);
                    oscillator.start();
                    this.localStream = destination.stream;

                    oscillator.disconnect();
                }
            }

            this.localStream.getTracks().forEach((track) => {
                peer.connection.addTrack(track, this.localStream);
            });

            const isMuted = localStorage.getItem("selfMuted") === "true";
            if (isMuted) {
                this.muteLocalMicrophone(true);
            }
        } catch (error) {
            console.error("Error setting up local stream:", error);
        }

        const offer = await peer.connection.createOffer();
        await peer.connection.setLocalDescription(offer);

        return { peerId, offer };
    }

    async handleConnectionRequest(peerId, offer) {
        const peer = this.getPeerConnection(peerId);
        peer.isInitiator = false;

        if (!this.localStream && !this.promptedMic) {
            try {
                await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        autoGainControl: true,
                    },
                });
            } catch (error) {
                console.error("Error getting local stream:", error);
            }
        }

        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
                peer.connection.addTrack(track, this.localStream);
            });
        }

        await peer.connection.setRemoteDescription(
            new RTCSessionDescription(offer),
        );

        const answer = await peer.connection.createAnswer();
        await peer.connection.setLocalDescription(answer);

        return {
            peerId,
            answer,
        };
    }

    async handleAnswer(peerId, answer) {
        const peer = this.peers.get(peerId);
        if (peer && peer.isInitiator) {
            await peer.connection.setRemoteDescription(
                new RTCSessionDescription(answer),
            );
        }
    }

    async handleIceCandidate(peerId, candidate) {
        const peer = this.peers.get(peerId);
        if (peer) {
            try {
                await peer.connection.addIceCandidate(
                    new RTCIceCandidate(candidate),
                );
            } catch (e) {
                console.error(
                    `Error adding ICE candidate for peer ${peerId}:`,
                    e,
                );
            }
        }
    }

    monitorSpeaking(peerId) {
        const peer = this.peers.get(peerId);
        if (!peer || !peer.analyser) return;

        const analyser = peer.analyser;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        const threshold = 10;

        const checkSpeaking = () => {
            analyser.getByteFrequencyData(dataArray);
            const average =
                dataArray.reduce((sum, value) => sum + value, 0) /
                dataArray.length;
            const isSpeaking = average > threshold;

            if (isSpeaking !== peer.isSpeaking) {
                peer.isSpeaking = isSpeaking;
                this.notifySpeakingState(peerId, isSpeaking);
            }

            if (peer.analyser) {
                requestAnimationFrame(checkSpeaking);
            }
        };

        checkSpeaking();
    }

    sendToPeer(peerId, message) {
        const peer = this.peers.get(peerId);
        if (peer?.dataChannel?.readyState === "open") {
            peer.dataChannel.send(message);
            return true;
        }
        return false;
    }

    broadcast(message) {
        let sentCount = 0;
        for (const [peerId, peer] of this.peers) {
            if (this.sendToPeer(peerId, message)) {
                sentCount++;
            }
        }
        return sentCount;
    }

    onMessage(callback) {
        this.onMessageCallbacks.add(callback);
    }

    onPeerConnected(callback) {
        this.onPeerConnectedCallbacks.add(callback);
    }

    onPeerDisconnected(callback) {
        this.onPeerDisconnectedCallbacks.add(callback);
    }

    removeMessageCallback(callback) {
        this.onMessageCallbacks.delete(callback);
    }

    removePeerConnectedCallback(callback) {
        this.onPeerConnectedCallbacks.delete(callback);
    }

    removePeerDisconnectedCallback(callback) {
        this.onPeerDisconnectedCallbacks.delete(callback);
    }

    notifyMessage(peerId, message) {
        for (const callback of this.onMessageCallbacks) {
            callback(peerId, message);
        }
    }

    notifyPeerConnected(peerId) {
        for (const callback of this.onPeerConnectedCallbacks) {
            callback(peerId);
        }
    }

    notifyPeerDisconnected(peerId) {
        for (const callback of this.onPeerDisconnectedCallbacks) {
            callback(peerId);
        }
    }

    onIceCandidate(peerId, candidate) {
        console.log(`New ICE candidate for peer ${peerId}:`, candidate);
    }

    getConnectedPeers() {
        return Array.from(this.peers.keys());
    }

    isConnectedToPeer(peerId) {
        const peer = this.peers.get(peerId);
        return peer?.connection.connectionState === "connected";
    }

    setPeerAudioVolume(peerId, volume) {
        const peer = this.peers.get(peerId);
        if (peer && peer.gainNode) {
            peer.gainNode.gain.value = Math.max(0, Math.min(1, volume));
        }
    }

    setPeerAudioMuted(peerId, muted) {
        const peer = this.peers.get(peerId);
        if (peer) {
            this.incomingTrackIds.get(peerId).forEach((trackId) => {
                const track = this.remoteAudioStream.getTrackById(trackId);
                if (track) {
                    track.enabled = !muted;
                }
            });
        }
    }

    setAllMuted(muted) {
        this.remoteAudioStream.getAudioTracks().forEach((track) => {
            track.enabled = !muted;
        });
    }

    muteLocalMicrophone(muted) {
        if (this.localStream) {
            this.localStream.getAudioTracks().forEach((track) => {
                track.enabled = !muted;
            });
        }
    }

    disconnectFromPeer(peerId) {
        const peer = this.peers.get(peerId);
        if (peer) {
            if (peer.audioSourceNode) {
                peer.audioSourceNode.disconnect();
            }
            if (peer.gainNode) {
                peer.gainNode.disconnect();
            }
            if (peer.analyser) {
                peer.analyser.disconnect();
            }
            if (peer.dataChannel) {
                peer.dataChannel.close();
            }
            if (peer.connection) {
                peer.connection.getSenders().forEach((sender) => {
                    if (sender.track) {
                        sender.track.stop();
                        this.remoteAudioStream.removeTrack(sender.track);
                    }
                });
                peer.connection.close();
            }
            this.peers.delete(peerId);
        }
    }

    disconnectFromAll() {
        for (const peerId of this.peers.keys()) {
            this.disconnectFromPeer(peerId);
        }
        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => track.stop());
            this.localStream = null;
        }
    }
}

