import { usePlayerContext } from "./PlayerContext.jsx"; // Adjust the path as necessary

export function usePlayerContextManager() {
    const {
        addPlayer,
        addPlayers,
        removePlayer,
        editPlayer,
        getPlayerById,
        getAllPlayers,
    } = usePlayerContext();

    return {
        addPlayer,
        addPlayers,
        removePlayer,
        editPlayer,
        getPlayerById,
        getAllPlayers,
    };
}

