import PropTypes from "prop-types";
import { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import StartGame from "./main";
import { EventBus } from "./EventBus";
import { usePlayerContextManager } from "../context/PlayerContextManager";
import Network from "../network/Network";
import { handleAuth } from "../utils/auth";
export const PhaserGame = forwardRef(function PhaserGame(
    { currentActiveScene },
    ref,
) {
    const game = useRef();
    const playerContextManager = usePlayerContextManager();

    async function initializeAudioContext() {
        try {
            const localStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                },
            });
            return localStream;
        } catch (err) {
            console.error("Microphone permission denied", err);
            return null;
        }
    }

    const checkLoggedIn = async () => {
        let token = localStorage.getItem("ut");
        let exp = localStorage.getItem("exp");

        if (token && exp) {
            const expiryDate = new Date(parseInt(exp) * 1000);
            if (expiryDate > new Date()) {
                const localStream = await initializeAudioContext();
                new Network(
                    playerContextManager,
                    token,
                    localStream !== null,
                    localStream,
                );
            } else {
                await handleAuth();
                token = localStorage.getItem("ut");
                const localStream = await initializeAudioContext();
                new Network(
                    playerContextManager,
                    token,
                    localStream !== null,
                    localStream,
                );
            }
        } else {
            await handleAuth();
            token = localStorage.getItem("ut");
            const localStream = await initializeAudioContext();
            new Network(
                playerContextManager,
                token,
                localStream !== null,
                localStream,
            );
        }
    };

    useEffect(() => {
        checkLoggedIn();
    }, []);

    // Create the game inside a useLayoutEffect hook to avoid the game being created outside the DOM
    useLayoutEffect(() => {
        if (game.current === undefined) {
            game.current = StartGame("game-container");

            if (ref !== null) {
                ref.current = { game: game.current, scene: null };
            }
        }

        return () => {
            if (game.current) {
                game.current.destroy(true);
                game.current = undefined;
            }
        };
    }, [ref]);

    useEffect(() => {
        EventBus.on("current-scene-ready", (currentScene) => {
            if (currentActiveScene instanceof Function) {
                currentActiveScene(currentScene);
            }
            ref.current.scene = currentScene;
        });

        return () => {
            EventBus.removeListener("current-scene-ready");
        };
    }, [currentActiveScene, ref]);

    return <div id="game-container"></div>;
});

// Props definitions
PhaserGame.propTypes = {
    currentActiveScene: PropTypes.func,
};

